import React from 'react'
import Link from 'gatsby-link'
import Helmet from 'react-helmet'

import {
  Layout,
  BannerSmall,
  TextBox,
  Appointment,
  CallUs, 
  QuickFacts,
  BannerLinks,
  Individualsoftware,
  ErpModule,
  GridComponent,
  SplitView,
  BannerCTA,
  VideoAndText
} from '../components'

import mac from '../assets/projekte/ikb/internet2.png'

import contacts from '../components/CallUs/DefaultContacts'

import BannerImage from '../assets/header.individual.jpg'
import JoinTheTeam from '../assets/bannerlinks/jointheteam.jpg'
import VerlaesslicheBeratung from '../assets/bannerlinks/verlaessliche.beratung.jpg'
import Georg from '../assets/georg_neu.png'
import taskimage from '../assets/Auftragsabwicklung.png'


import Momo from '../assets/individual-mockups/Momo-Mockup.png'
import Eglo from '../assets/individual-mockups/Eglo-Mockup.png'
import Prinoth from '../assets/individual-mockups/Prinoth-Mockup.png'
import AK from '../assets/individual-mockups/AK-Mockup.png'
import WKO from '../assets/individual-mockups/WKO-Mobile.png'

const facts = [
  {
    count: 250,
    suffix: '',
    text: 'Zufriedene Kund:innen'
  },
  {
    count: 100,
    suffix: '%',
    text: 'lokaler Ansprechpartner'
  },
  {
    count: 42798,
    suffix: '',
    text: 'GIT commits'
  }
]

const boxes = [
  {
    text: 'Join the team!',
    image: JoinTheTeam,
    link: '/karriere',
    linkText: 'Bewerbung'
  },
  {
    text: 'Verlässliche Beratung',
    image: VerlaesslicheBeratung,
    link: '/kontakt',
    linkText: 'Kontakt'
  }
]

const IndividualSoftware = () => (
  <Layout>
    <Helmet
      title="Individualsoftware, die sich an Ihre Bedürfnisse anpasst."
      meta={[
        {
          name: 'description',
          content:
            'Die holzweg GmbH konzipiert und kreiert massgeschneiderte Software- & App-Lösungen mit passgenauen Schnittstellen zu unterschiedlichsten Systemen. Kostenersparnis und Wettbewerbsvorteil!'
        },
        {
          name: 'keywords',
          content:
            'programmierung, individual, individuelle, software, Programme, Software, Individual, Eigen, massgeschneidert, App, Lösung, Schnittstellen, individual-software'
        },
        {
          property: 'og:description',
          content:
            'Die holzweg GmbH konzipiert und kreiert massgeschneiderte Software- & App-Lösungen mit passgenauen Schnittstellen zu unterschiedlichsten Systemen. Kostenersparnis und Wettbewerbsvorteil!'
        },
        {
          property: 'og:image',
          content: 'https://holzweg.com/static/header.individual-0d7ba341e653812895fda7274df1d1eb.jpg'
        }
      ]}
    />
    <BannerSmall
      image={BannerImage}
      title={
        <span>
          Individualsoftware
          <br />
          by holzweg
        </span>
      }
    />
    <TextBox
      title="Maßgeschneidert passt!"
      text={
        <span>
          <p>
            Die holzweg GmbH<sup>®</sup> baut individuelle Software für Ihr
            Unternehmen. Wir passen die Software an Sie an, nicht umgekehrt!
            Klingt gut? Ist gut und schaut gut aus :)
          </p>
        </span>
      }
    />
    <QuickFacts facts={facts} color="gray" />
    <SplitView
      displayImageLeft={true}
      backgroundImage={Momo}
      title="Momo – custom ERP-System"
      link="/hw-erp"
      small={true}
      linkText="Mehr erfahren"
      text=
      {
        <>
          <p>
            Ein ERP-System (Enterprise Resource Planning) ist
            unverzichtbar für Unternehmen. Es integriert und optimiert
            Geschäftsprozesse, bietet Echtzeit-Überwachung, steigert die
            Effzienz und verschafft Wettbewerbsvorteile. Wir bei holzweg
            schaffen individuelle Systeme für Ihr Unternehmen.
          </p>
          <br />
          <p>
            Momo wurde speziell für Unternehmen im Bereich
            Straßenmarkierung entwickelt und ist seitdem kontinuierlich
            bei <a class="button--color-orange" href='https://www.morf-ag.ch/'>MORF</a> im Einsatz.
          </p>
        </>
      }
      listHeading="Was kann Momo?"
      listDescription="Ein Überblick über die Vorteile"
      lists=
      {[
        {
          head: "Digitale Baustellenassistenz",
          content: [
            "Erfassung von erledigten Positionen und Einsatzbedingungen",
            "Einsicht von To-dos, Örtlichkeit, Positionsdetails",
            "Direkt vor Ort und offine verwendbar"
          ]
        },
        {
          head: "Unternehmensverwaltung",
          content: [
            "Mitarbeiter:innen",
            "Adressen Kund:innen",
            "Verwaltung Maschinen & Fahrzeuge",
            "Positionsvorlagen"
          ]
        },
        {
          head: "Projektverwaltung",
          content: [
            "Workflows",
            "Rabatte, Skonto, Rechnungen",
            "Daten als PDF Druck/ Speicherung",
            "Dateiablage projektspezifischer Daten"
          ]
        },
        {
          head: "Agile Webentwicklung",
          content: [
            "Für individuell angepasste Anwendungsfälle",
            "Agile und flexible Weiterentwicklung",
            "Keine Updateprobleme"
          ]
        },
      ]}
    />

    <SplitView
      displayImageLeft={true}
      backgroundImage={AK}
      title="Quiz - Online mit Köpfchen"
      link="/projekte/arbeiterkammer"
      linkText="Mehr erfahren"
      small={true}
      text={
        <>
          <p>
            Wir hatten die Gelegenheit, für die <a class="button--color-orange" href='https://www.arbeiterkammer.at/index.html'>Arbeiterkammer Tirol</a> ein Quiztool zu
            entwickeln, das es Schülerinnen und Schülern ermöglicht,
            Themen wie Urheberrecht, Datenschutz und
            Medienkompetenz spielerisch zu erarbeiten.
          </p>
          <br />
          <p>
          Unser Ziel war es, eine fröhliche Oberfläche zu schaffen und
          leicht verständliche Fragetypen zu entwickeln. Überzeugen Sie
          sich selbst:
          </p>
        </>
      }
    />
    <div className="video-and-text__video">
      <iframe className='individual-iframe' src="https://www.youtube-nocookie.com/embed/NhhHsmJs9zI?si=pdlfl1Ds9x1swX-R?rel=0&enablejsapi=1" />
    </div>

    <Appointment image={Georg} />

    <SplitView
      displayImageLeft={false}
      backgroundImage={Eglo}
      title="Globales B2B Shopsystem"
      link="/projekte/eglo-pim"
      linkText="Mehr erfahren"
      small={true}
      text=
      {<>
        <p>
          Um den weltweiten Leuchten-Verkauf zu optimieren, hat
          holzweg ein individuelles B2B Shopsystem für <a class="button--color-orange" href='https://www.eglo.com/at/'>EGLO </a>
          entwickelt. Das bestehende Magento-Shop-System wurde
          schrittweise durch einen individuell entwickelten Shop
          abgelöst.
        </p>
        <br />
        <p>
          EGLO ist der Weltmarktführer für Leuchten mit über 38.000
          Produkten im Sortiment und in 29 Verkaufsorganisationen
          tätig.
        </p>
      </>}
      listHeading="Highlights des B2B Systems"
      lists=
      {[
        {
          content: [
            "Download-System für Produkt-Listen, Datenblätter, Bildmaterial und Anleitungen von Gigabyte-großen Daten",
            "Automatisierte Benachrichtigungen bei Sortimentsänderungen oder aktualisierten Bildern",
            "Merklisten von Kunden oder Mitarbeiter:innen"
          ]
        },
        {
          content: [
            "Leistungsfähige Produkt-Suche mit QR-Codes und EAN-Barcodes",
            "Personalisierte Dashboards und Specialpromo-Portal für besondere Verkaufstage",
            "Kunden-Registrierung mit mehrstufigem Verfahren, unterschiedlichen Rechten und Betreuer:innen"
          ]
        },
      ]}
    />
    <SplitView
      displayImageLeft={false}
      backgroundImage={WKO}
      title="Lernapp für Lehrlinge"
      link="/projekte/wirtschaftskammer"
      linkText="Mehr erfahren"
      mid={true}
      text=
      {<>
        <p>
          Holzweg hat die Lernapp speziell für Lehrlinge entwickelt. Die
          App vermittelt nicht nur Wissen durch eine Art digitales
          Lehrbuch, sondern bietet den Lehrlingen auch die Möglichkeit,
          ein Quiz für das Selbststudium zu nutzen.
        </p>
        <br />
        <p>
          In Zusammenarbeit mit der <a class="button--color-orange" href='https://www.wko.at/tirol'>Wirtschaftskammer Tirol</a> wurde die
          App entwickelt und wird aktiv von auszubildenden Lehrlingen
          aus unterschiedlichen Sparten genützt.
        </p>
      </>}
      listHeading="Das bietet die Lernapp"
      lists=
      {[
        {
          content: [
            "Wiki und Quiz in einer App",
            "Die App ist einfach und intuitiv zu bedienen, Inhalte werden durch einen handbuchähnlichen Charakter vermittelt",
            "Inhalte können in Form eines Quiz zum Selbststudium abgefragt werden"
          ]
        },
        {
          content: [
            "Bilder, Audio und Video können integriert werden",
            "Responsive und schlichtes Design, auf Desktop und mobil (allen Endgeräten) verfügbar",
            "schnell, offine verwendbar und installierbar"
          ]
        },
      ]}
    />

    <GridComponent />

    <SplitView
      displayImageLeft={true}
      backgroundImage={Prinoth}
      title="Dokumenten-Management-System"
      small={true}
      text={
        <>
          <p>
            Wir haben für <a class="button--color-orange" href='https://www.prinoth.com/'>Prinoth</a> ein individuelles und flexibles DMS
            entwickelt, das auf deren Bedürfnisse und Anforderungen
            zugeschnitten ist. Unser DMS basiert auf einem PHP Backend
            mit einer minimalistischen JavaScript Web-Oberfläche.
          </p>
          <br />
          <p>
            Es bietet zwei Funktionen, die besonders hervorragen: ein
            komplexes Rechte-System eine mehrstufige Authentifizierung.
          </p>
        </>
      }
      textBoxes=
      {[
        <>
          <p>
            Das umfangreiche <b>Rechte-System</b> ermöglicht es, verschiedene Benutzergruppen und Zugriffsrechte für Ihre Dokumente zu definieren. Es können auch Rechte erworben oder vergeben werden, um den Zugriff auf bestimmte PDFs zu ermöglichen bzw. zu beschränken.
          </p>
          <br />
          <p>
            Die <b>mehrstufige Authentifizierung</b> erhöht die Sicherheit der Dokumente, indem sich User nicht nur mit einem Passwort, sondern auch mit einem weiteren Faktor, in unserem Fall einem Code der per E-Mail zugesandt wird, anmelden müssen.
          </p>
        </>
      ]}
    />
    <TextBox
      title="Intranet"
      text={
        <span>
          <p>
            Ein von uns entwickeltes Intranet für die <a class="individual-software__text-button button--color-orange" href='https://www.neueheimat.tirol/'>Neue Heimat Tirol </a>
            (NHT) optimiert Geschäftsprozesse, erleichtert interne
            Kommunikation und den Zugriff auf Informationen. Das Portal
            fördert den Dialog zwischen Management und Mitarbeitenden,
            steigert die Produktivität und reduziert interne Kosten.
            Die nachhaltige Umsetzung basiert auf dem CMS Typo3 auf
            Open Source Basis, welche flexible Anpassung, Skalierbarkeit
            und einfache Wartung für kontinuierliche Innovation und
            Sicherheit gewährleistet.
          </p>
        </span>
      }
      buttons={[
        {link: "/projekte/nht", text: "Mehr erfahren", color: "black", type: "cta-round"}
      ]}
    />

    {/* <TextBox
      title=""
      text={
        <span>
          <h3>Und wie das alles geht?</h3>
          <p>
            Holzweg hebt sich durch ausgeprägtes Know-how in Individualprogrammierung ab. Komplexe Themen wie <b>maßgeschneiderte Software- und App-Lösungen</b> sowie <b>passgenaue Schnittstellen zu verschiedenen Systemen</b> wecken unsere Leidenschaft. Wir konzipieren Lösungen auf hohem Niveau, um redundante Arbeiten zu minimieren, Effizienz zu steigern und Kosteneinsparungen sowie Wettbewerbsvorteile zu bieten.
          </p>
          <p>
            Unsere Expertise basiert auf <b>bewährten <a href='http://www.holzweg.com/technologie'>Technologien</a></b> und Open Source Softwarelösungen. Dort, wo Standardlösungen nicht genügen, bringen wir <b>Know-how von Konzeption bis Umsetzung</b> ein. Einbindung des Kunden ist integral, transparente Kommunikation und agiles Projektmanagement durch Scrum-Methoden sind essenziell.
          </p>
          <p>
            Unser Ziel: In kurzer Zeit und <b>ohne unnötige Kosten</b> ein funktionsfähiges Produkt liefern. Regelmäßige Abstimmung ermöglicht Einblicke, iterative Verbesserungen und aktive Teilnahme an Diskussionen. Jetzt ist der perfekte Zeitpunkt für eine <b>maßgeschneiderte Individual-Software</b>, die auf Ihre Prozesse zugeschnitten ist und einen <b>sicheren Wettbewerbsvorteil</b> verschafft.
          </p>
          <br />
          <br />
        </span>
      }
    />
    <ErpModule
      title="Was ist das holzweg Custom ERP?"
      text={<>
        <span>
          <p>
            Ein ERP-System (Enterprise Resource Planning) ist unverzichtbar für Unternehmen. Es integriert und optimiert Geschäftsprozesse, bietet Echtzeit-Überwachung, steigert die Effizienz und verschafft Wettbewerbsvorteile. Unsere maßgeschneiderte Software, entwickelt mit modernster Technologie, steigert ebenfalls Effizienz und Wettbewerbsvorteil. Von Dokumenten-Management-Systemen bis zu Web-Applikationen bieten wir individuelle Lösungen an. Investieren Sie in die Zukunft Ihres Unternehmens und kontaktieren Sie uns für maßgeschneiderte Software, die Ihr Unternehmen voranbringt.
          </p>
        </span>
      </>}
      textBelow={
        <p>
          Kontaktieren Sie uns gerne, um mehr über unsere ERP-Lösungen, maßgeschneiderte Software oder Webanwendungen zu erfahren und Ihr Unternehmen auf die nächste Stufe zu bringen.
          Mit unserer langjährigen Erfahrung und Expertise in der Entwicklung von maßgeschneiderten ERP Systemen, maßgeschneiderter Software und Webanwendungen können wir Ihnen helfen, Ihr Unternehmen zu digitalisieren und Ihre Geschäftsprozesse zu optimieren.
        </p>
      }
      image={taskimage}
      link="../hw-erp"
    /> */}

    <CallUs contacts={contacts} title="Rufen Sie uns an!" />
    <BannerLinks boxes={boxes} />
  </Layout>
)

export default IndividualSoftware